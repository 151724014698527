<template>
  <div>
    <ViewTemplateReportList :panel="panel" :reports="reports" />
  </div>
</template>

<script>
import ViewTemplateReportList from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportList";

export default {
  name: "ReportRentalView",
  components: { ViewTemplateReportList },
  data() {
    return {
      panel: {
        module: "Locação",
        title: "Relatório",
        showFilter: false,
        showSearch: true,
        showButtons: false,
      },
      reports: {
        default: [
          {
            name: "Locação por Cliente",
            description: "Filtre as locações por data de cadastro, efetivação e outros critérios...",
            routeName: "reportRentByCustomer",
          },
          {
            name: "Locação por Período",
            description: "Filtre as locaçoes pelo período da locação e outros critérios...",
            routeName: "reportRentByPeriodRent",
          },
          {
            name: "Locação por Produto",
            description: "Filtre as locaçoes pelo produto e outros critérios...",
            routeName: "reportRentByProduct",
          },
          {
            name: "Pagamentos por Locação",
            description: "Filtre as condições de pagamentos das locações e outros critérios...",
            routeName: "reportRentByPayment",
          },
        ],
        saved: [],
      },
    };
  },
};
</script>
